import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useSnackbar } from 'notistack';
import { Skeleton } from '@mui/material';
import LeadCard from './LeadCard';
import SmallHeader from '../Van/singleVanPage/SmallHeader';
import SmallFooter from 'src/components/Footer/SmallFooter';
import CheckoutForm from '../Order/Stripe';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { dineroFormat, dineroSubtractAmount } from 'src/common/utils/dataFormat';
import { AvailableLead, LeadData } from 'src/store/reducers/order/types';
import './styles.scss';

interface IProps {
  type: string;
}

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY!);

const PaymentPage: FC<IProps> = ({ type }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const { checkQuoteOrder, payDueOrder, manualPayOrder, setupFilters } = useActions();
  const { result, quoteCheck, payment } = useTypedSelector(state => state.order);
  const [isAvailable, setIsAvailable] = useState(false);
  const [showPrice, setShowPrice] = useState('');

  useEffect(() => {
    if (id) {
      if (type === 'lead') checkQuoteOrder(id);
      else if (type === 'due') payDueOrder(id);
      else if (type === 'manual') manualPayOrder(id);
    }
  }, [id, type]);

  useEffect(() => {
    if (result.check) {
      if (result.check === 200) {
        if (!(quoteCheck as AvailableLead)!.clientSecret) {
          setIsAvailable(false);
          navigate('/');
          setupFilters({ ...(quoteCheck as LeadData), page: 1, take: 10 });
          enqueueSnackbar('Unfortunately, the van is already booked. Please choose another one.', {
            variant: 'info'
          });
        } else setIsAvailable(true);
      } else navigate('/');
    }
  }, [result.check]);

  useEffect(() => {
    if (result.payment === 200) {
      const order = (payment as AvailableLead).order;
      if (type === 'due') {
        const due = dineroSubtractAmount(order.price, order.partial_paid!, order.currency);
        setShowPrice(dineroFormat(due, order.currency));
      } else if (type === 'manual') {
        const handlePayment = order.handle_payments?.filter(p => p.stripe_id.startsWith('mp-'))[0];
        if (handlePayment) {
          const price = Number(handlePayment.stripe_id.split('-')[1]);
          setShowPrice(dineroFormat(price, order.currency));
        }
      }
      setIsAvailable(true);
    } else if (result.payment === 400) {
      setIsAvailable(false);
      enqueueSnackbar(payment as string, {
        variant: 'error'
      });
      navigate('/');
    }
  }, [result.payment]);

  return (
    <>
      <div className='header'>
        <SmallHeader />
      </div>
      <div className='order-payment check-quote'>
        <div className='col-2 order-payment__container'>
          <div className='order-payment__container__cart-container'>
            {isAvailable ? (
              <LeadCard
                order={
                  type === 'lead'
                    ? (quoteCheck as AvailableLead).order
                    : (payment as AvailableLead).order
                }
                showPrice={showPrice}
              />
            ) : (
              <Skeleton sx={{ maxWidth: '100%' }} height='100%' style={{ transform: 'none' }} />
            )}
          </div>
          <div className='order-payment__price-off'>
            <div className='order-payment__container__payment-container'>
              <p>
                You can book now with a fully refundable down payment applied towards your rental.
              </p>
              <p>
                We'll email a secure link to pay your remainder before your rental. You may pay with
                the same or a different card.
              </p>
              <p>
                You may
                <a
                  href='https://www.sprinter-rentals.com/cancellation'
                  target='_blank'
                  style={{ textDecoration: 'underline' }}
                >
                  cancel
                </a>
                free up to 5 days prior to your trip.
              </p>
            </div>
            <div className='order-payment__payment'>
              {isAvailable ? (
                <Elements
                  options={{
                    clientSecret:
                      type === 'lead'
                        ? (quoteCheck as AvailableLead).clientSecret
                        : (payment as AvailableLead).clientSecret,
                    appearance: {
                      theme: 'stripe'
                    }
                  }}
                  stripe={stripePromise}
                >
                  <CheckoutForm startDate={Date.now().toString()} />
                </Elements>
              ) : (
                <Skeleton sx={{ maxWidth: '100%' }} height={230} style={{ transform: 'none' }} />
              )}
            </div>
          </div>
        </div>
      </div>
      <SmallFooter />
    </>
  );
};

export default PaymentPage;
