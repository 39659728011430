import { FC } from 'react';
import AddLocationAltIcon from '@mui/icons-material/AddLocationAlt';
import { dineroFormat, dineroGetPercentage, fromISOtoFormat } from 'src/common/utils/dataFormat';
import { IOrderForClient } from 'src/models/IOrder';

interface IProps {
  order: IOrderForClient;
  showPrice?: string;
}

const LeadCard: FC<IProps> = ({ order, showPrice }) => {
  const {
    location,
    sub_location,
    start_date,
    end_date,
    end_location,
    price,
    currency,
    fee_list,
    van
  } = order;
  const subId = process.env.REACT_APP_NODE_ENV ? 18 : 27;

  return (
    <div className='order-cart'>
      <div className='order-cart__header'>
        <div style={{ fontWeight: 600 }}>Booking</div>
        <div className='order-cart__header__date'>
          <div className='order-cart__header__location'>{location}</div>
          <div className='order-cart__header__sub-location'>{sub_location}</div>
          <div className='order-cart__header__date'>{fromISOtoFormat(start_date, 'ff')}</div>
          <div className='order-cart__header__location'>{end_location || ''}</div>
          <div>{fromISOtoFormat(end_date, 'ff')}</div>
          <p style={{ marginTop: '10px' }}>You can pick your van 24/7 with a reservation.</p>
        </div>
      </div>
      <div className='order-cart__wrapper'>
        <div className='order-cart__item'>
          <div className='order-cart__item__img'>
            <img src={van!.photos?.exterior[0]} alt='van' />
          </div>
          <div className='order-cart__item__info'>
            <div className='order-cart__item__info__title'>{van!.mark}</div>
            <div className='order-cart__item__info__description'>{van!.model}</div>
          </div>
          <div className='order-cart__item__price'>
            {`${dineroFormat(van!.price, van!.currency)}/day`}
          </div>
        </div>
        {fee_list.map((item, key) => (
          <div className='order-cart__item' key={key}>
            <div className='order-cart__item__img'>
              {item.id === subId ? (
                <AddLocationAltIcon fontSize='large' />
              ) : (
                <img src={item.logo} alt='extra' />
              )}
            </div>
            <div className='order-cart__item__info'>
              <div className='order-cart__item__info__title'>
                {item.id === subId ? sub_location : item.name}
              </div>
              <div className='order-cart__item__info__description'>{item.title}</div>
            </div>
            <div className='order-cart__item__price'>{dineroFormat(item.price, item.currency)}</div>
          </div>
        ))}
      </div>
      <div className='order-cart__total-price'>
        <p className='order-cart__total-price__title'>Total incl. all fees / taxes</p>
        <div className='extra-card__price order-cart__total-price__price'>
          {dineroFormat(price, currency)}
        </div>
      </div>
      <div className='order-cart__wrapper-total'>
        <div className='order-cart__wrapper-total__info'>
          <p className='order-cart__wrapper-total__info__price'>
            {showPrice ? showPrice : dineroFormat(dineroGetPercentage(price, 20), currency)}
          </p>
          {!showPrice && (
            <p className='order-cart__wrapper-total__info__title'>
              Refundable down payment to reserve your van
            </p>
          )}
          <p className='order-cart__wrapper-total__info__subtitle'>
            Applied towards your rental, fully refundable.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LeadCard;
