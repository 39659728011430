import { put, takeLatest } from 'redux-saga/effects';
import instance from '../set';
import { OrderActionEnum } from 'src/store/reducers/order/types';
import { OrderActionCreators } from 'src/store/reducers/order/action-creators';

function* fetchPayDueOrder({ id }: ReturnType<typeof OrderActionCreators.payDueOrder>) {
  try {
    const { status, data } = yield instance.get(`booking/payDue/${id}`);
    yield put(OrderActionCreators.resultPaymentOrder(status, data));
  } catch (error: any) {
    console.log('error :>> ', error);
    yield put(OrderActionCreators.resultPaymentOrder(400));
  }
}
export function* actionWatcherPayDueOrder() {
  yield takeLatest(OrderActionEnum.REQUEST_PAY_DUE, fetchPayDueOrder);
}
