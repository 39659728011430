import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { Backdrop, Modal, Skeleton } from '@mui/material';
import CheckoutForm from 'src/pages/Order/Stripe';
import ModalAction from 'src/components/Items/Actions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { useActions } from 'src/hooks/useActions';
import { AvailableLead, LeadData } from 'src/store/reducers/order/types';

interface IProps {
  open: boolean;
  handleClose: () => void;
}

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY!);

const CheckLeadModal: FC<IProps> = ({ open, handleClose }) => {
  const navigate = useNavigate();
  const { setupFilters } = useActions();
  const { isLoading, quoteCheck } = useTypedSelector(state => state.order);

  const handleSelect = () => {
    setupFilters({ ...(quoteCheck as LeadData), page: 1, take: 10 });
    navigate('/');
  };

  return (
    <Modal open={open} closeAfterTransition BackdropComponent={Backdrop} onClose={handleClose}>
      <div className='client__modal edit-dates-modal'>
        {isLoading.check ? (
          <Skeleton sx={{ maxWidth: '100%' }} height={230} style={{ transform: 'none' }} />
        ) : (quoteCheck as AvailableLead) && (quoteCheck as AvailableLead).clientSecret ? (
          <Elements
            options={{
              clientSecret: (quoteCheck as AvailableLead).clientSecret,
              appearance: {
                theme: 'stripe'
              }
            }}
            stripe={stripePromise}
          >
            <CheckoutForm startDate={Date.now().toString()} />
          </Elements>
        ) : (
          <>
            <div className='client__modal__title'>
              Unfortunately, the van is already booked. You can choose another one.
            </div>
            <ModalAction
              type='another van'
              action='Select'
              handleClose={handleClose}
              handleSubmit={handleSelect}
            />
          </>
        )}
      </div>
    </Modal>
  );
};
export default CheckLeadModal;
