import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from '../Order/Stripe';
import SmallHeader from '../Van/singleVanPage/SmallHeader';
import Footer from 'src/components/Footer';
import './styles.scss';

const stripePromise = loadStripe(process.env.REACT_APP_PUBLISHABLE_KEY!);

const HoldPage: FC = () => {
  const { id } = useParams();
  const clientSecret = id;

  const options: any = {
    clientSecret,
    appearance: {
      theme: 'stripe'
    }
  };

  return (
    <>
      <div className='header'>
        <SmallHeader />
      </div>
      <div className='payment'>
        <p className='payment__description'>
          A $250 hold will be placed and refunded within 3 days after you return, minus any
          incidentals. Please click here to add your card or use any listed driver's card.
        </p>
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      </div>
      <div className='footer'>
        <Footer />
      </div>
    </>
  );
};

export default HoldPage;
