import { FC, useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import AdditionalDriverPage from 'src/pages/AdditionalDriver';
import CancelBookingPage from 'src/pages/CancelBooking';
import PaymentPage from 'src/pages/Payment';
import ClientPage from 'src/pages/Client';
import ContractPage from 'src/pages/Contract';
import DriverLicensePage from 'src/pages/DriverLicense';
import ErrorPage from 'src/pages/Error';
import LoginPage from 'src/pages/Login';
import MainPage from 'src/pages/Main';
import OrderPage from 'src/pages/Order';
import ExpiredPage from 'src/pages/Order/Pages/Expired';
import SuccessPage from 'src/pages/Order/Pages/Success';
import HoldPage from 'src/pages/Hold';
import QuotePage from 'src/pages/SuccessQuote';
import VanPage from 'src/pages/Van/singleVanPage';
import { useTypedSelector } from 'src/hooks/useTypeSelector';

const MainRouters: FC = () => {
  const {
    order: {
      van: { id }
    },
    van: { isFilterValidate },
    global: { error }
  } = useTypedSelector(state => state);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    error &&
      enqueueSnackbar(`${error?.status} ${error?.result}`, {
        variant: 'error'
      });
  }, [error]);

  return (
    <>
      <Routes>
        <Route index element={<MainPage />} />
        <Route
          path='order'
          element={id && isFilterValidate ? <OrderPage /> : <Navigate to='/' replace />}
        />
        <Route path='signed' element={<SuccessPage type={'signed'} />} />
        <Route
          path='loaderio-20cdf133f3b846f185cdb73c15a280be.txt'
          element={'../../../public/loaderio-20cdf133f3b846f185cdb73c15a280be.txt'}
        />

        <Route path='success/upload' element={<SuccessPage type={'upload'} />} />
        <Route path='success/payment' element={<SuccessPage type={'payment'} />} />
        <Route path='success/driver-invite' element={<SuccessPage type={'driver-invite'} />} />
        <Route path='hold/:id' element={<HoldPage />} />
        <Route path='expired' element={<ExpiredPage />} />
        <Route path='license/:id' element={<DriverLicensePage />} />
        <Route path='contract/:id' element={<ContractPage />} />
        <Route path='cancel' element={<CancelBookingPage />} />
        <Route path='driver/add/:id' element={<AdditionalDriverPage />} />
        <Route path='van/143:id' element={<VanPage />} />
        <Route path='host/:id' element={<MainPage />} />
        <Route path='client/login/callback' element={<LoginPage />} />
        <Route path='booking/lead/:id' element={<PaymentPage type='lead' />} />
        <Route path='booking/paydue/:id' element={<PaymentPage type='due' />} />
        <Route path='booking/manualpay/:id' element={<PaymentPage type='manual' />} />
        <Route path='client' element={<ClientPage />} />
        <Route path='quote' element={<QuotePage />} />
        <Route path='*' element={<ErrorPage />} />
      </Routes>
    </>
  );
};

export default MainRouters;
