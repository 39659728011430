import { FC, useEffect, useState } from 'react';
import { Button, CircularProgress } from '@mui/material';
import CheckLeadModal from './CheckLeadModal';
import { useActions } from 'src/hooks/useActions';
import { useTypedSelector } from 'src/hooks/useTypeSelector';
import { dineroFormat, dineroSubtract, dineroSubtractPrice } from 'src/common/utils/dataFormat';
import { IOrderForClient } from 'src/models/IOrder';
import './styles.scss';
import './media.scss';

interface IProps {
  order: IOrderForClient;
  isQuoted?: boolean;
}

const PaymentsCard: FC<IProps> = ({ order, isQuoted }) => {
  const { payNow, checkQuoteOrder, sendReceipt } = useActions();
  const clientCard = useTypedSelector(state => state.client.client?.card);
  const clientLoading = useTypedSelector(state => state.client.isLoading);
  const { isLoading, result } = useTypedSelector(state => state.order);
  const { id, price, currency, partial_paid } = order;

  const [open, setOpen] = useState(false);

  useEffect(() => {
    result.check === 400 && setOpen(false);
  }, [result.check]);

  const handleOpen = () => {
    checkQuoteOrder(id);
    setOpen(true);
  };

  return (
    <>
      <div className='payments-card'>
        <div className='client__order__main__header'>
          <h4>Payments</h4>
        </div>
        <div className='payments-card__container'>
          <p>
            {clientCard && clientCard.length && <b>Your card is on file **{clientCard}</b>}
            <br />
            You can settle up now.
            <br />
            Please Pay 72h before, due 24h before.
          </p>
          <div className='payments-card__payment'>
            <div className='payments-card__payment__main'>
              <span className='payments-card__title'>Total incl Fees:</span>
              <span className='payments-card__price blue'>{dineroFormat(price, currency)}</span>
            </div>
            {!isQuoted && (
              <div
                className='payments-card__payment__receipt'
                onClick={() => sendReceipt(order.id, String(partial_paid))}
              >
                Get Receipt
              </div>
            )}
          </div>
          <div className='payments-card__payment__main'>
            <span className='payments-card__title'>Paid:</span>
            <span className='payments-card__price green'>
              {dineroFormat(partial_paid, currency)}
            </span>
          </div>
        </div>
        <div className='payments-card__footer'>
          <div>
            <span className='payments-card__title'>Open Balance:</span>
            <span className='payments-card__price red'>
              {dineroSubtractPrice(price, partial_paid, currency)}
            </span>
          </div>
          {isQuoted ? (
            <Button
              onClick={handleOpen}
              children={isLoading.check ? <CircularProgress size={15} /> : 'Down Paid'}
              disabled={isLoading.check}
            />
          ) : (
            <Button
              onClick={() => payNow(id)}
              children={clientLoading.pay ? <CircularProgress size={15} /> : 'Settle Now'}
              disabled={clientLoading.pay || !dineroSubtract(price, partial_paid)}
            />
          )}
        </div>
      </div>
      <CheckLeadModal open={open} handleClose={() => setOpen(false)} />
    </>
  );
};

export default PaymentsCard;
