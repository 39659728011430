import { put, takeLatest } from 'redux-saga/effects';
import instance from '../set';
import { OrderActionEnum } from 'src/store/reducers/order/types';
import { OrderActionCreators } from 'src/store/reducers/order/action-creators';

function* fetchManualPayOrder({ id }: ReturnType<typeof OrderActionCreators.manualPayOrder>) {
  try {
    const { status, data } = yield instance.get(`booking/manualPay/${id}`);
    yield put(OrderActionCreators.resultPaymentOrder(status, data));
  } catch (error: any) {
    console.log('error :>> ', error);
    yield put(OrderActionCreators.resultPaymentOrder(400));
  }
}
export function* actionWatcherManualPayOrder() {
  yield takeLatest(OrderActionEnum.REQUEST_PAY_MANUAL, fetchManualPayOrder);
}
