import {
  Base64Object,
  ChangeOrderDatesAction,
  CheckTokenAction,
  ClearClientResultAction,
  ClientActionEnum,
  ErrorLoginAction,
  RequestClientAction,
  RequestPayNowAction,
  ResultChangeOrderDatesAction,
  ResultCheckTokenAction,
  ResultClientAction,
  ResultPayNowAction,
  ResultSendReceiptAction,
  ResultShowContractAction,
  ResultShowInsuranceAction,
  ResultShowLicenseAction,
  ResultSignupAction,
  ResultUpdateClientAction,
  ResultUpdateOrderAction,
  SendReceiptAction,
  ShowContractAction,
  ShowInsuranceAction,
  ShowLicenseAction,
  SignupAction,
  UpdateClientAction,
  UpdateOrderAction
} from './types';
import { IClientFetch, IOrderChangeDates, IOrderForClient } from 'src/models/IOrder';

export const ClientActionCreators = {
  getClient: (id: string): RequestClientAction => ({
    type: ClientActionEnum.REQUEST_CLIENT,
    id
  }),
  resultGetClient: (data: any, status: string | number): ResultClientAction => ({
    type: ClientActionEnum.RESULT_CLIENT,
    data,
    status
  }),
  updateClient: (currentClient: Partial<IClientFetch>): UpdateClientAction => ({
    type: ClientActionEnum.REQUEST_UPDATE_CLIENT,
    currentClient
  }),
  resultUpdateClient: (status: number): ResultUpdateClientAction => ({
    type: ClientActionEnum.RESULT_UPDATE_CLIENT,
    status
  }),
  updateOrder: (currentOrder: Partial<IOrderForClient>): UpdateOrderAction => ({
    type: ClientActionEnum.REQUEST_UPDATE_ORDER,
    currentOrder
  }),
  resultUpdateOrder: (status: number): ResultUpdateOrderAction => ({
    type: ClientActionEnum.RESULT_UPDATE_ORDER,
    status
  }),
  payNow: (id: number): RequestPayNowAction => ({
    type: ClientActionEnum.REQUEST_CLIENT_PAY,
    id
  }),
  resultPayNow: (status: string | number, data?: string): ResultPayNowAction => ({
    type: ClientActionEnum.RESULT_CLIENT_PAY,
    status,
    data
  }),
  signup: (email: string): SignupAction => ({
    type: ClientActionEnum.REQUEST_SIGNUP,
    email
  }),
  resultSignup: (status: string | number): ResultSignupAction => ({
    type: ClientActionEnum.RESULT_SIGNUP,
    status
  }),
  checkToken: (token: string): CheckTokenAction => ({
    type: ClientActionEnum.CHECK_TOKEN,
    token
  }),
  ResultCheckToken: (status: number): ResultCheckTokenAction => ({
    type: ClientActionEnum.RESULT_CHECK_TOKEN,
    status
  }),
  errorLogin: (): ErrorLoginAction => ({
    type: ClientActionEnum.ERROR_LOGIN
  }),
  logOut: () => ({ type: ClientActionEnum.EXIT }),
  clearClientResult: (): ClearClientResultAction => ({
    type: ClientActionEnum.CLEAR_CLIENT_RESULT
  }),
  showContract: (key: string): ShowContractAction => ({
    type: ClientActionEnum.REQUEST_SHOW_CONTRACT,
    key
  }),
  resultShowContract: (data: Base64Object): ResultShowContractAction => ({
    type: ClientActionEnum.RESULT_SHOW_CONTRACT,
    data
  }),
  showLicense: (key: string): ShowLicenseAction => ({
    type: ClientActionEnum.REQUEST_SHOW_LICENSE,
    key
  }),
  resultShowLicense: (data: Base64Object): ResultShowLicenseAction => ({
    type: ClientActionEnum.RESULT_SHOW_LICENSE,
    data
  }),
  showInsurance: (key: string): ShowInsuranceAction => ({
    type: ClientActionEnum.REQUEST_SHOW_INSURANCE,
    key
  }),
  resultShowInsurance: (data: Base64Object): ResultShowInsuranceAction => ({
    type: ClientActionEnum.RESULT_SHOW_INSURANCE,
    data
  }),
  sendReceipt: (orderId: number, handlePaymentId?: string): SendReceiptAction => ({
    type: ClientActionEnum.REQUEST_SEND_RECEIPT,
    orderId,
    handlePaymentId
  }),
  resultSendReceipt: (status: number, data?: string): ResultSendReceiptAction => ({
    type: ClientActionEnum.RESULT_SEND_RECEIPT,
    status,
    data
  }),
  changeOrderDates: (dates: IOrderChangeDates, orderId: number): ChangeOrderDatesAction => ({
    type: ClientActionEnum.REQUEST_CHANGE_DATES,
    dates,
    orderId
  }),
  resultChangeOrderDates: (
    status: number,
    data?: IOrderForClient
  ): ResultChangeOrderDatesAction => ({
    type: ClientActionEnum.RESULT_CHANGE_DATES,
    status,
    data
  })
};
