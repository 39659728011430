import { IVanFetch } from './../../../models/IVan';
import { OrderAction, OrderActionEnum, OrderState } from './types';

const initialState: OrderState = {
  client: {
    last_name: '',
    first_name: '',
    email: '',
    phone: '',
    country: 'sass',
    city: 'scss'
  },
  extras: [],
  van: {} as IVanFetch,
  price: 0,
  currency: 'USD',
  note: '',
  isPartial: true,
  isLoading: {
    quote: false,
    check: false,
    payment: false
  },
  result: {}
};

const order = (state = initialState, action: OrderAction): OrderState => {
  switch (action.type) {
    case OrderActionEnum.SETUP_ORDER:
      return { ...state, van: action.van };
    case OrderActionEnum.CHANGE_ORDER:
      return { ...state, [action.name]: action.value };
    case OrderActionEnum.RESULT_ADD_CLIENT:
      return {
        ...state,
        client: {
          ...state.client,
          id: action.client.id
        },
        result: {
          ...state.result,
          client: action.status
        }
      };
    case OrderActionEnum.REQUEST_QUOTE_ORDER:
      return { ...state, isLoading: { ...state.isLoading, quote: true } };
    case OrderActionEnum.RESULT_QUOTE_ORDER:
      return {
        ...state,
        isLoading: { ...state.isLoading, quote: false },
        result: { ...state.result, quote: action.statusText }
      };
    case OrderActionEnum.REQUEST_CHECK_QUOTE:
      return { ...state, isLoading: { ...state.isLoading, check: true } };
    case OrderActionEnum.RESULT_CHECK_QUOTE:
      return {
        ...state,
        quoteCheck: action.data,
        isLoading: { ...state.isLoading, check: false },
        result: { ...state.result, check: action.status }
      };
    case OrderActionEnum.REQUEST_PAY_DUE:
      return { ...state, isLoading: { ...state.isLoading, payment: true } };
    case OrderActionEnum.REQUEST_PAY_MANUAL:
      return { ...state, isLoading: { ...state.isLoading, payment: true } };
    case OrderActionEnum.RESULT_PAY:
      return {
        ...state,
        payment: action.data,
        isLoading: { ...state.isLoading, payment: false },
        result: { ...state.result, payment: action.status }
      };
    case OrderActionEnum.INIT_ORDER:
      return (state = initialState);
    default:
      return state;
  }
};
export default order;
